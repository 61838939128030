.login {
  background: url('https://edge.leadnomics.com/assets/side-nav-splash.jpg');
  background-position-x: $loginBackgroundPosition;
  background-repeat: no-repeat;
  background-size: cover;
  min-height:100vh;
  color: $loginColor;
  a {
    color: $loginColor;
    font-weight: $loginFontWeight;
  }
  .birdDogNavTitle {
    color: $birdDogNavTitleColor;
    padding-left:0px;
    font-size: 72px;
    line-height: 56px;
    margin-top: 70px;
  }
  .dim>.columns {
    max-width:40vw;
    padding-left:50px;
    padding-right:50px;
    margin-top:0px;
    &>.column {
      max-width:500px;
      margin:0 auto;
    }
  }
  label, label.checkbox, label.checkbox:hover {
    color:$birdDogNavTitleColor;
  }
  .forgot-password {
    margin-top:20px;
    text-align:right;
  }
  button {
    margin-top:20px;
  }
  .loginLine {
    border-top: $loginLine;
  }
  @include touch {
    background-position-x: 0;
    color:white;
    .birdDogNavTitle {
      color:white;
    }
    .title {
      color: white;
    }
    .dim {
      background: linear-gradient(45deg, black, transparent);
      min-height: 100vh;
    }
    .dim>.columns {
      max-width:100vw;
      padding-left:20px;
      padding-right:20px;
      margin:0;
      &>.column {
        max-width:500px;
        margin:0 auto;
      }
    }
    label, label.checkbox, label.checkbox:hover {
      color:white;
    }
  }
}
