
.offer-search {
  display:inline-block;
  width:calc(100% - 60px);
  max-width: 400px;
}


.offer-filters {
  display:inline-block;
  width:50px;
  margin-left:10px;
}

.offer {

  .box {
    margin-bottom:100px;
  }
  .field-name {
    font-weight: 800;
    text-transform: uppercase;
    font-size: 0.9em;
  }
  .offer-field {
    margin-bottom:5px;
  }
  .link-text {
    word-break: break-word;
  }
  .suboffers {
    margin-top:100px;
    .suboffertype {
      margin-left:20px;
    }
  }

  .notification {
    background: #696969;
    color:white;
  }
}

.filterTag {
  color:white;
  font-size: 12px;
  margin-right: 4px;
  padding: 4px;
  border-radius: 4px;
  font-weight: 800;
  text-transform: uppercase;
}
