.hide-transition {
    width: 100%;
    height: 58px; // Matches search bar height
    transition: all;
    &.hidden {
        width: 0;
        height: 0;
        * {
            display: none;
        }
    }
}



.ln-hamburger {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: flex-start;
}


.birdDogNavTitle {
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 41px;
    padding-top: 20px;
    padding-bottom: 40px;
    padding-right: 50px;
    text-align: left;
    padding-left: 20px;
    line-height: 35px;

    &.inverted {
      color: black;
    }

    .edge {
      color:$primary;
    }
    .nomics {
      display:block;
    }

    .line {
      display: block;

      &.is-primary {
        color: $birdDogNavTitlePrimary;
      }
    }    
}

.navItemsContainer {
  margin-top:10px;
  margin-bottom:10px;
  height:60px;
}
.navItems {
    color: white;
    text-align: left;
    font-weight: bold;
    font-size: 18px;
    font-family: "Headline";
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
    display: flex;
    justify-content: left;
    align-items: center;
    &:hover {
      color:$primary;
    }
    .icon {
      margin-right:20px;
      display: none;
    }
    &.active  {
        background-color: $primary;
        border-left: $primaryColorDark 10px solid;
        padding-left: 15px;

    }

    &.active:hover {
      color:#333;
    }
}
.navManagerItems {
  color: white;
  text-align: left;
  font-size: 12px;
  padding-right: 20px;
  padding-bottom: 5px;
  padding-left: 20px;
  justify-content: left;
  align-items: center;
}


.sidenav {
    background-color:rgb(11, 11, 11);
    background-image: url('../assets/brand/BRANDED_CONTENT/imgs/side-nav-splash.jpg');
    background-position-y: -190px;
    background-repeat: no-repeat;
    background-size:470%;
    min-height: 100vh;
    width: $sideNavWidth;
    min-width: $sideNavWidth;
    z-index: 40;
    overflow-y: auto;

    @include mobile {
        position: fixed;
        z-index:0;
        width: 100%;
        min-width: 100%;

    }
}


.navbar.authorized {
    width: 100%;
    left: unset;
    right: 0;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    height: .5rem;

    .button.account {
      background:white;
      color:black;
      border:none;
      height:100%;
      text-align: left;
      line-height: 12px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      &>svg {
        width:2em;
        height:2em;
        margin-right:9px;
      }

      &.affiliate-as-admin {
        background-color: red;
      }

    }

    .button.search {
      background:white;
      color:black;
      border:none;
    }
    @include touch {
        width: 100%
    }

    .global-search {
        width: 40%;
        padding: 10px;
    }

    .expanding-search-bar {
        display: none;
        width: 0;

        &.expanded {
            display: flex;
            align-items: center;
            width: 100%;

            .global-search {
                flex: 1;
                padding-left: unset
            }
        }
    }

    .nav-controls {
        width: 100%;
    }
}
.wrapper {
  overflow-x: auto;
}

.main {
  @include touch {
    transition: transform 0.25s linear;
    -webkit-transition: transform 0.25s linear;
    will-change: transform;
    transform:translateX(0%);
  }
}

.main-sticky {
  @include touch {
    transition: transform 0.25s linear;
    -webkit-transition: transform 0.25s linear;
    will-change: transform;
    transform:translateX(0%);
  }
}

.expanded {
  &.wrapper {
    overflow-x:hidden;
  }

  .main {
    transition: transform 0.25s linear;
    -webkit-transition: transform 0.25s linear;
    will-change: transform;
    transform:translateX(90%);
  }
  .main-sticky {
    transition: transform 0.25s linear;
    -webkit-transition: transform 0.25s linear;
    will-change: transform;
    transform:translateX(90%);
  }
}

.ln-footer {
  margin-top: auto;
  padding: 15px;
  &.level {
    display: block;
    font-size: 0.8em;
  }

  .level-right {
    margin-top: 0;

    @include mobile {
      .level-item {
        justify-content: flex-start;
      }
    }
  }
}

.footer-links > :not(:last-child) {
  margin-right: 0.75em;
}