
// TODO - rename
.error-bar {
    padding-top: 0.6em;
    padding-bottom: 0.6em;
    padding-left: 10px;
    padding-right: 10px;
    position: fixed;
    width: calc(100% - #{$sideNavWidth});
    right: 0;
    z-index: 50;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mobile {
        width: 100%;
    }

    &.is-fullwidth {
        width: 100%;
    }

    .error-link {
        cursor: pointer;
        text-decoration: underline;

        &:hover {
            color: #333;
        }
    }
}