.changelog-change:not(:first-child) {
    margin-top: 10px;
}

.change-log-object {
    min-width: 150px;

    &:not(:first-child) {
        margin-top: 10px;
    }
}