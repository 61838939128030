.report-stale {
  background: rgb(255, 255, 163);
  padding: 10px;
  display: block;
  margin: 0 auto;
  max-width: 859px;
  margin-bottom: 20px;
  text-align: center;
}

.report-bar-chart {
  .recharts-tooltip-item-separator, .recharts-tooltip-item-unit {
    display: none;
  }
}